import {POST_INIT, INIT, setInit} from '../../../actions/app/init/init.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setFormuleChoisie, setLoader, setStep} from '../../../actions/app/ui/ui.actions'
import {clearLocalStorage, setLocalStorage} from "../../../actions/core/localStorage/localStorage.actions";
import _ from "lodash";
import {setTarif} from "../../../actions/app/tarif/tarif.actions";
import {setContract} from "../../../actions/app/contract/contract.actions";
import {APP_NAME} from '@amo/core/constants';
import moment from "moment/moment.js";
// import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";
// import queryString from "query-string";

export const initMiddleware = () => next => action => {
    next(action)

    const { payload } = action
    let nextActions = [];

    switch (action.type){

        case POST_INIT:

            const body = payload.body.key ? _.omit(payload.body, 'token') : payload.body

            next([
                apiRequest({body, method: 'POST', url: payload.data, entity: INIT, otherData: payload.meta.otherData}),
                setLoader({state: true, entity: INIT})
            ])
            break

        case `${INIT} ${API_SUCCESS}`:

            const {state, token, data: { source }, whitelabel} = action.payload.data
            let formule = {};
            const local = JSON.parse(window.localStorage.getItem(`${APP_NAME}-source`))

            /* Vérifie si la signature a été signé */
            const isSign = _.some(_.map(['sign_return'], w => window.location.href.includes(w)), Boolean)

            window.history.pushState(null, null, `/${window.location.search}`)

            /* Vérifie si le paiement a échoué */
            const paiementIsError = _.some(_.map(['paid=0'], w => window.location.href.includes(w)), Boolean)
            if (state === 'devis' && paiementIsError){
                next([
                    setInit({init: payload.data}),
                    setStep({step: 6}),
                    setError({state: false, entity: INIT}),
                    setLoader({state: false, entity: INIT})
                ])
            }

            /* Verifie la source pour conserver le canal d'origine et si courtier on le met a jour */
            if ((local && local.source) !== source || (source === 'courtier' && action.payload.meta.otherData.key)){
                window.localStorage.setItem(`${APP_NAME}-source`, JSON.stringify({source, key: action.payload.meta.otherData.key }))
            }

            /* si la source n'est pas le direct on remplace les variables par le WhiteLabel */
            const couleurSite = (source === 'site' && payload.data.apporteur.codeBelair === 43397)
            if (!couleurSite){
                for (let [key, value] of Object.entries(whitelabel)) {
                    if (_.startsWith(key, 'style_color')){
                        document.documentElement.style.setProperty(`--${key}`, value)
                    }
                }
            }

            // En attente de validation et d'acotivation coté Nicolas
            // if (queryString.parse(window.location.search).paid === '1' && action.payload.data?.data?.Devis ) {
            //     nextActions.push(
            //         analyticsSetEvent({
            //             event: 'purchase',
            //             ap1: action.payload.data?.data?.DemandeTarif?.Police?.IdApporteur1,
            //             datas: {
            //                 ecommerce: {
            //                     currency: 'EUR',
            //                     value: action.payload.data.data.Devis.Formule.PrimeAnnuelleFracAnnuel,
            //                     transaction_id: action.payload.data.data.Contrat.Police || '-',
            //                     coupon: action.payload.data?.Tarif?.CodePromo?.Eligible ? action.payload.data?.DemandeTarif?.CodePromo?.CodePromo : '',
            //                     phoneNumber : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.TelPortable.replace(/\s/g, ''), // à voir
            //                     birthDate : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.DateNaissance ? moment(action.payload.data.data.DemandeTarif.ListePersonnes[0].DateNaissance, 'DD/MM/YYYY').format('YYYYMMDD') : null,
            //                     firstName : _.toLower(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Prenom),
            //                     lastName : _.toLower(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Nom),
            //                     street : `${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse1}${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse2 ? ` ${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse2}` : ''}`,
            //                     zipCode : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.CP,
            //                     city : _.lowerCase(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Ville).replace(/\s/g, ''),
            //                     items: [{
            //                         item_name: `Assurance ${action.payload.data.data.Devis.Vehicule.FamilleProduit}`,
            //                         price: action.payload.data.data.Devis.Formule.PrimeAnnuelleFracAnnuel,
            //                         item_category: action.payload.data.data.Devis.Vehicule.FamilleProduit,
            //                         item_variant: action.payload.data.data.DemandeTarif.Police.FormuleChoisie,
            //                         quantity: '1'
            //                     }]
            //                 }
            //             }
            //         })
            //     )
            // }

            switch (state) {
                case 'devis':
                case 'new':
                    nextActions.push(
                        setInit({init: payload.data}),
                        clearLocalStorage({entity: INIT}),
                        setLocalStorage({entity: INIT, storage: {token}}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT})
                    )
                    break

                case 'tarif':
                    nextActions.push(
                        setInit({init: payload.data}),
                        setLocalStorage({entity: INIT, storage: {token}}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT})
                    )
                    break

                case 'contrat':
                    nextActions.push(
                        setInit({init: payload.data}),
                        setLocalStorage({entity: INIT, storage: {token}}),
                        setContract({data: payload.data.data.Contrat}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT})
                    )

                    if(payload.data.data.Tarif) {
                        formule = _.find(payload.data.data.Tarif.ListeFormules, ['CodeFormule', payload.data.data.Devis.Formule.CodeFormule])
                        nextActions.push(
                            setFormuleChoisie({formule}),
                            setTarif({tarif: payload.data.data.Tarif})
                        )
                    }

                    const signature = _.head(payload.data.signatures)

                    let step = signature && !signature.signed && !signature.expired && !signature.refused ? 5 : 6;

                    if (payload.data.data.source === 'courtier' || payload.data.data.source === 'conseiller' || isSign){
                        step = 6
                    }

                    nextActions.push(setStep({step}))

                    break


                case 'contrat_en_attente_de_paiement':
                    //On vérifie si on est au retours du paiement email et que l'on est courtier
                    const paiementEmail = payload.data.data.source !== "site" && _.every(_.map(['pay=1', 'by_mail=1'], w => window.location.href.includes(w)), Boolean)

                    if (!paiementEmail){
                        nextActions.push(
                            setInit({init: payload.data}),
                            setLocalStorage({entity: INIT, storage: {token}}),
                            setStep({step: payload.data.data.source === 'site' ? 7 : 1}),
                            setError({state: false, entity: INIT}),
                            setLoader({state: false, entity: INIT})
                        )
                    } else {
                        formule = _.find(payload.data.data.Tarif.ListeFormules, ['CodeFormule', payload.data.data.Devis.Formule.CodeFormule])

                        nextActions.push(
                            setInit({init: payload.data}),
                            setLocalStorage({entity: INIT, storage: {token}}),
                            setTarif({tarif: payload.data.data.Tarif}),
                            setFormuleChoisie({formule}),
                            setStep({step: 4.5}),
                            setError({state: false, entity: INIT}),
                            setLoader({state: false, entity: INIT})
                        )
                    }

                    break

                case 'contrat_en_cours_de_validation':
                    formule = _.find(payload.data.data.Tarif.ListeFormules, ['CodeFormule', payload.data.data.Devis.Formule.CodeFormule])

                    nextActions.push(
                        setInit({init: payload.data}),
                        setLocalStorage({entity: INIT, storage: {token}}),
                        setTarif({tarif: payload.data.data.Tarif}),
                        setFormuleChoisie({formule}),
                        setStep({step: 8}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT}),
                    )

                    break

                default:
                    return null
            }

            next(nextActions);

            break

        case `${INIT} ${API_ERROR}`:
            next([
                clearLocalStorage({entity: INIT}),
                setError({state: true, entity: INIT}),
                setLoader({state: false, entity: INIT})
            ])
            break

        default:
            return null
    }
}
