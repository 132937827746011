import {createSelector} from 'reselect'
import _ from 'lodash'

/* Init */
const initSelector = state => state.init

/* Liste garanties par formule */
const formuleSelectedSelector = (state, code) => _.find(state.tarif.ListeFormules, ['CodeFormule', code]) || {}

/* State */
export const getStateContract = createSelector(
    initSelector,
    init => init.state
)

/* Source */
export const getSource = createSelector(
    initSelector,
    init => init.data.source
)

/* DemandeTarif */
const demandeTarifSelector = state => state.init.data.DemandeTarif

export const getToken = createSelector(
    initSelector,
    init => init.token
)

export const getFormuleChoisie = createSelector(
    demandeTarifSelector,
    tarif => tarif.Police.FormuleChoisie !== undefined ? String(tarif.Police.FormuleChoisie) : undefined
)

export const getPCC1 = createSelector(
    demandeTarifSelector,
    tarif => {
        const options = _.find(tarif.ListeOptions, ['CodeOption', 'PCC1'])
        return options && options.Souscrite
    }
)

export const getSouscripteurDefine = createSelector(
    demandeTarifSelector,
    tarif => tarif.ListePersonnes && tarif.ListePersonnes.length > 0
)

export const getSouscripteur = createSelector(
    demandeTarifSelector,
    tarif => tarif.ListePersonnes[0]
)

export const getCiviliteSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.Civilite
)

export const getNomSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.Nom
)

export const getPrenomSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.Prenom
)

export const getBirthCountrySouscripteur= createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.BirthCountry
)
export const getBirthCitySouscripteur= createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.BirthCity
)
export const getBirthNameSouscripteur= createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.BirthName
)

export const getTypeSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.TypePersonne
)

export const getDateNaissanceSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.DateNaissance
)

export const getAdresse1Souscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.Adresse1
)

export const getAdresse2Souscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.Adresse2
)

export const getVilleSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.Ville
)

export const getCPSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.CP
)

export const getTelPortableSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.TelPortable
)

export const getEmailSouscripteur = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.Email
)

export const getNumeroClientSouscripteur  = createSelector(
    getSouscripteur,
    souscripteur => souscripteur && souscripteur.NumeroClient !== undefined ? String(souscripteur.NumeroClient) : undefined
)

/* DemandeContrat */
const demandeContratSelector = state => state.init.data.DemandeContrat

export const getDateHeureEffet = createSelector(
    demandeContratSelector,
    contrat => contrat.DateHeureEffet
)
export const getNomTitulaireDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.NomTitulaire
)
export const getadrIBANDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.adrIBAN
)
export const getvilleIBANDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.villeIBAN
)
export const getcpIBANDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.cpIBAN
)
export const getIBANDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.IBAN
)
export const getBICDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.BIC
)
export const getModePaiementComptantDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.ModePaiementComptant
)
export const getSignaturePhoneDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.SignaturePhone
)

/* Tiers payeur */
export const getTiersPayeurDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.TiersPayeur || {}
)
export const getTiersPayeurTypeDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.Type
)
export const getTiersPayeurLastNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.LastName
)
export const getTiersPayeurBirthNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.BirthName
)
export const getTiersPayeurFirstNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.FirstName
)
export const getTiersPayeurBirthDateDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.BirthDate
)
export const getTiersPayeurBirthCountryDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.BirthCountry
)
export const getTiersPayeurBirthCityDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.BirthCity
)
export const getTiersPayeurRelationshipDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.Relationship
)
export const getTiersPayeurThirdPayerReasonDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.ThirdPayerReason
)

/* Contrat */

const contratSelector = state => state.init.data.Contrat;

export const getContratInit = createSelector(initSelector, (init) => init.data.Contrat || {});
export const getPoliceContratInit = createSelector(getContratInit, (contrat) => contrat.Police);

/* Sign Url */

export const getSignUrl = createSelector(
    contratSelector,
    contrat => contrat.Signature.Url
)

export const getSignaturesInit = createSelector(
    initSelector,
    init => init.signatures
)

export const getUrlSignaturesInit = createSelector(
    getSignaturesInit,
    signatures => signatures.length > 0 ? signatures[0].url : null
)

/* Apporteur */

const apporteurSelector = state => state.init.apporteur

export const getEmailApporteur = createSelector(
    apporteurSelector,
    apporteur => apporteur.emailVente
)

export const getApporteur1Init = createSelector(
    initSelector,
    init => init.apporteur1
)

/* Signature */

const signatureSelector = state => state.init.signatures

export const getSignature = createSelector(
    signatureSelector,
    signatures => _.head(signatures)
)

/* whitelabel */
export const getLogoWhitelabel = createSelector(
    initSelector,
    init => (init.whitelabel && init.whitelabel.logo_url) || null
)

export const getNameWhitelabel = createSelector(
    initSelector,
    init => init.whitelabel.name
)

export const getIsDirect = createSelector(
    initSelector,
    init => init.data.source === 'site'
)

export const getIsMB = createSelector(
    initSelector,
    getSource,
    (init, source) => source === "site" && init.apporteur.codeBelair !== 43397
)

export const getEmailDPOWhitelabel = createSelector(
    initSelector,
    init => init.whitelabel.email_dpo
)

export const getUrlSiteWhitelabel = createSelector(
    initSelector,
    init => init.whitelabel.url_site
)

/* Paiement */
const paiementSelector = state => state.init.payments

export const getAmount = createSelector(
    paiementSelector,
    paiement => paiement[0].amount
)
export const getPaiementHistory = createSelector(
    paiementSelector,
    paiement => _.last(paiement).history
)

export const getIfPaiementSendByMail = createSelector(
    paiementSelector,
    paiement => _.find(paiement, {'paid': false, 'by_mail': true})
)

/* Devis */
const devisSelector = state => state.init.data.Devis

export const getPCCInDevisInit = createSelector(
    devisSelector,
    devis => _.find(devis.Formule.ListeGaranties, {'Souscrite': true, 'CodeGarantie': 'PCC1'})
)
export const getIDDevisInDevisInit = createSelector(
    devisSelector,
    devis => devis.IDDevis
)

export const getGestionnaireInit = createSelector(
    initSelector,
    init => init.gestionnaire
)

export const getDocumentsUrlInit = createSelector(
    initSelector,
    init => init.documents_url
)

export const getPoliceDemandeTarifInit = createSelector(
    initSelector,
    init => init.data.DemandeTarif.Police || {}
)

export const getTauxApporteur = createSelector(
    getPoliceDemandeTarifInit,
    police => police.TauxApporteur1
)

export const getHonorairesApporteur = createSelector(
    getPoliceDemandeTarifInit,
    police => police.Honoraires
)

export const getMotifAvenantInit = createSelector(
    getPoliceDemandeTarifInit,
    police => police.MotifAvenant
)

export const getCodeBelairApporteur1Init = createSelector(
    initSelector,
    init => init.apporteur1.codeBelair
)

export const getApporteurCanUseRecueilBesoin = createSelector(
    initSelector,
    init => {
        const { immatOrias, formeJuridique, partenaires, villercs, nrc } = init.apporteur
        return Boolean(immatOrias && formeJuridique && partenaires && villercs && nrc)
    }
)
/* Devoir Conseil */
export const getDevoirConseilDemandeTarifInit = createSelector(
    initSelector,
    init => init.data.DemandeTarif.DevoirConseil || {}
)
export const getDevoirVolDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    devoirConseil => devoirConseil.DevoirIC !== undefined ? String(devoirConseil.DevoirIC) : undefined
)
export const getDevoirUsageDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    devoirConseil => devoirConseil.DevoirUsage !== undefined ? String(devoirConseil.DevoirUsage) : undefined
)

export const getGarantiesSouscriteOption = createSelector(
    formuleSelectedSelector,
    formule => _.orderBy(_.filter(formule.ListeGaranties, { TypeGarantie: 'Option', Disponible: 'Option', Souscrite: true}), ['OrdreAffichage'], ['asc'])
)
export const getGarantiesOption = createSelector(
    formuleSelectedSelector,
    formule => _.orderBy(_.filter(formule.ListeGaranties, { TypeGarantie: 'Option', Disponible: 'Option'}), ['OrdreAffichage'], ['asc'])
)

/* Acte Gestion */
export const getActeGestionDemandeTarifInit = createSelector(
    initSelector,
    init => init.data.DemandeTarif.ActeGestion
)

/* Personne conducteur */
export const getSouscripteurDemandeTarif = createSelector(
    initSelector,
    init => _.find(init.data.DemandeTarif.ListePersonnes, {'TypePersonne': 'P', 'RoleConducteur': 'P'}) || {}
)
export const getNomSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.Nom
)

export const getPrenomSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.Prenom
)

export const getTypeSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.TypePersonne
)

export const getDateNaissanceSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.DateNaissance
)

export const getAdresse1SouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.Adresse1
)

export const getAdresse2SouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.Adresse2
)

export const getVilleSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.Ville
)

export const getCPSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.CP
)

export const getTelPortableSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.TelPortable === "" ? souscripteur.TelFixe : souscripteur.TelPortable
)

export const getEmailConducteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Email
)

export const getCiviliteConducteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Civilite
)
