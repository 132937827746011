import {connect} from 'react-redux'
import moment from "moment";
import _ from 'lodash'
import Formulaire from './Formulaire'
import {bindActionCreators, compose} from "redux"
import {getMaxStep, getPrixTotalAnnuel, getStep} from "../../../../redux/selectors/ui/ui.selectors";
import {setStep} from "../../../../redux/actions/app/ui/ui.actions";
import {postFill} from "../../../../redux/actions/app/fill/fill.actions";
import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';
import {
    getActeGestionDemandeTarifInit,
    getAdresse1Souscripteur,
    getAdresse1SouscripteurDemandeTarif,
    getAdresse2SouscripteurDemandeTarif,
    getadrIBANDemandeContratInit,
    getApporteurCanUseRecueilBesoin,
    getBICDemandeContratInit,
    getBirthCitySouscripteur,
    getBirthCountrySouscripteur,
    getBirthNameSouscripteur,
    getCiviliteConducteurDemandeTarif,
    getCodeBelairApporteur1Init,
    getcpIBANDemandeContratInit,
    getCPSouscripteur,
    getCPSouscripteurDemandeTarif,
    getDateNaissanceSouscripteurDemandeTarif,
    getDevoirUsageDevoirConseilDemandeTarifInit,
    getDevoirVolDevoirConseilDemandeTarifInit,
    getEmailApporteur,
    getEmailConducteurDemandeTarif,
    getGestionnaireInit,
    getHonorairesApporteur,
    getIBANDemandeContratInit, getIfPaiementSendByMail,
    getModePaiementComptantDemandeContratInit,
    getMotifAvenantInit,
    getNomSouscripteur,
    getNomSouscripteurDemandeTarif,
    getNomTitulaireDemandeContratInit, getNumeroClientSouscripteur,
    getPCC1,
    getPrenomSouscripteur,
    getPrenomSouscripteurDemandeTarif,
    getSignaturePhoneDemandeContratInit,
    getSource,
    getTauxApporteur,
    getTelPortableSouscripteurDemandeTarif,
    getTiersPayeurBirthCityDemandeContratInit,
    getTiersPayeurBirthCountryDemandeContratInit,
    getTiersPayeurBirthDateDemandeContratInit,
    getTiersPayeurBirthNameDemandeContratInit, getTiersPayeurDemandeContratInit,
    getTiersPayeurFirstNameDemandeContratInit,
    getTiersPayeurLastNameDemandeContratInit,
    getTiersPayeurRelationshipDemandeContratInit,
    getTiersPayeurThirdPayerReasonDemandeContratInit, getTiersPayeurTypeDemandeContratInit,
    getToken,
    getTypeSouscripteurDemandeTarif,
    getvilleIBANDemandeContratInit,
    getVilleSouscripteur,
    getVilleSouscripteurDemandeTarif,
    getPoliceContratInit
} from "../../../../redux/selectors/init/init.selectors";
import {getFormValues, reduxForm} from "redux-form";
import {analyticsSetEvent} from "../../../../redux/actions/core/analytics/analytics.actions.js";
import {normalizeTel} from "@amo/core/utils/index.js";

const mapStateToProps = state => {
    const source = getSource(state);
    const data = getFormValues('devis')(state);

    return {
        data: data,
        prixTotalAnnuel: getStep(state) >= 1 && data?.DemandeTarif?.Police?.FormuleChoisie ? getPrixTotalAnnuel(state) : undefined,
        numeroContrat: getPoliceContratInit(state),
        source: source,
        paiementSendByMail: getIfPaiementSendByMail(state),
        token: getToken(state),
        step: getStep(state),
        maxStep: getMaxStep(state),
        query: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        gestionnaireIsAMO: getGestionnaireInit(state).codeBelair === 43397,
        apporteur: getCodeBelairApporteur1Init(state),
        acteGestion: getActeGestionDemandeTarifInit(state),
        initialValues:{
            "postalCodeCity-coordonnees": !!getCPSouscripteur(state),
            "postalCodeCity-villeIban": !!getcpIBANDemandeContratInit(state),
            DemandeDevis: {
                EmailCourtier: getEmailApporteur(state),
            },
            DemandeTarif:{
                UseRecueilBesoin: (getSource(state) === 'courtier' && !getApporteurCanUseRecueilBesoin(state)) || !_.includes([43397, 484568, 479835, 479846, 111619, 323284, 25399], getCodeBelairApporteur1Init(state)) ? 'non' : undefined,
                DevoirConseil: {
                    DevoirIC: getDevoirVolDevoirConseilDemandeTarifInit(state),
                    DevoirUsage: getDevoirUsageDevoirConseilDemandeTarifInit(state),
                },
                Police: {
                    FractionnementChoisi: 'A',
                    Honoraires: getHonorairesApporteur(state) ? getHonorairesApporteur(state) : 0,
                    TauxApporteur1: getTauxApporteur(state),
                    MotifAvenant: getMotifAvenantInit(state)
                },
                Vehicule: {
                    FamilleProduit: 'Trottinette'
                },
                ListePersonnes:[
                    {
                        Souscripteur: true,
                        RoleConducteur: 'P',
                        TypePersonne: getTypeSouscripteurDemandeTarif(state) || 'P',
                        DateNaissance: getDateNaissanceSouscripteurDemandeTarif(state) ? moment(getDateNaissanceSouscripteurDemandeTarif(state), 'DD/MM/YYYY') : undefined,
                        Nom: getNomSouscripteurDemandeTarif(state),
                        Prenom: getPrenomSouscripteurDemandeTarif(state),
                        Adresse1: getAdresse1SouscripteurDemandeTarif(state),
                        Adresse2: getAdresse2SouscripteurDemandeTarif(state),
                        Ville: getVilleSouscripteurDemandeTarif(state),
                        CP: getCPSouscripteurDemandeTarif(state),
                        TelPortable: normalizeTel(getTelPortableSouscripteurDemandeTarif(state)),
                        Email: getEmailConducteurDemandeTarif(state),
                        Civilite: `${getCiviliteConducteurDemandeTarif(state)}`,
                        BirthCountry: getBirthCountrySouscripteur(state),
                        BirthCity: getBirthCitySouscripteur(state),
                        BirthName: getBirthNameSouscripteur(state),
                        NumeroClient: getNumeroClientSouscripteur(state)
                    }
                ],
                ListeOptions: [
                    {
                        CodeOption: 'PCC1',
                        Souscrite: getPCC1(state) || false
                    }
                ],
            },
            DemandeContrat: {
                Signature: true,
                DateHeureEffet: moment(),
                NomTitulaire: getNomTitulaireDemandeContratInit(state) || getNomSouscripteur(state) && getPrenomSouscripteur(state) ? `${getNomSouscripteur(state)} ${getPrenomSouscripteur(state)}` : "",
                adrIBAN: getadrIBANDemandeContratInit(state) || getAdresse1Souscripteur(state),
                villeIBAN: getvilleIBANDemandeContratInit(state) || getVilleSouscripteur(state),
                cpIBAN: getcpIBANDemandeContratInit(state) || getCPSouscripteur(state),
                ModePaiementTerme: 'P',
                ModePaiementComptant: getModePaiementComptantDemandeContratInit(state),
                IBAN: getIBANDemandeContratInit(state),
                BIC: getBICDemandeContratInit(state),
                SignaturePhone: getSignaturePhoneDemandeContratInit(state),
                TiersPayeur: !_.isEmpty(getTiersPayeurDemandeContratInit(state)) ? {
                    Type: 'P',
                    LastName: getTiersPayeurLastNameDemandeContratInit(state),
                    BirthName: getTiersPayeurBirthNameDemandeContratInit(state),
                    FirstName: getTiersPayeurFirstNameDemandeContratInit(state),
                    BirthDate: moment(getTiersPayeurBirthDateDemandeContratInit(state), 'DD/MM/YYYY'),
                    BirthCountry: getTiersPayeurBirthCountryDemandeContratInit(state),
                    BirthCity: getTiersPayeurBirthCityDemandeContratInit(state),
                    Relationship: getTiersPayeurRelationshipDemandeContratInit(state),
                    ThirdPayerReason: getTiersPayeurThirdPayerReasonDemandeContratInit(state)
                } : {}
            }
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    myPostFill: ({acteGestion, step, maxStep, ap1, form, query}) => postFill({acteGestion, step, maxStep, ap1, form, query}),
    myPreviousPage: ({step}) => setStep({step}),
    analyticsSetEvent
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { myPostFill, myPreviousPage } = dispatchProps
    const { step, maxStep, apporteur, query, acteGestion } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        nextPage: (form) => {
            myPostFill({acteGestion, step, maxStep, ap1: apporteur, form, query})
        },
        previousPage: () => {
            window.scroll(0, 0)
            myPreviousPage({step: step -1})
        }
    }
}

const FormulaireContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devis',
        destroyOnUnmount: false
    })
)(Formulaire)

export default FormulaireContainer
